import { TDashboardView } from "../../interfaces";
import { ILineItemWithOrder, ISessionWithBookings } from "../../interfaces/Session";
import { useBookingSession } from "../../context/BookingSession";
import useSession from "./useSession";
import { useSession as useAuthSession } from "../../components/Session";
import { getDriverCount, getFinancialStatus, getSameSessionBookings, getStatusClass } from "./utilities";
import Notes from "./Notes";
import RaceReadyMsg from "./RaceReadyMsg";
import QrCode from "qrcode.react";
import { useDevice } from "../../hooks/useDevice";
import { useMemo } from "react";
import SessionVariantOptions from "./SessionVariantOptions";
import { useDriverRegistration } from "../../context/DriverRegistration";
import Name from "./Name";
import Occasion from "./Occasion";

interface Props {
  dashboardView: TDashboardView;
  session: ISessionWithBookings;
  handleViewOrderClick: (booking: ILineItemWithOrder) => void;
  handleViewRegistrationModalOpen: (booking: ILineItemWithOrder) => void;
}
function Session({ session, dashboardView, handleViewOrderClick, handleViewRegistrationModalOpen }: Props) {
  const { track: view, refreshSessions } = useBookingSession();
  const { permissions } = useAuthSession();
  const { bookings: registrationBookings } = useDriverRegistration();

  const trackStaff = permissions?.roles?.includes("TRACK_STAFF");
  const hasRrmFeatureAccess = permissions?.features?.includes("RRM");
  const isComplexView = useMemo(() => dashboardView === "COMPLEX_VIEW", [dashboardView]);
  const {
    RowClasses,
    staffCount,
    sessionTime,
    isBreakRace,
    filteredBookings,
    getBookingTime,
    getPaidPriceContent,
    sessionColor,
    rrmLog,
    // grmLogs,
    getRegisteredDriversCount,
  } = useSession({
    session,
    view,
    registrationBookings
  });

  const { isIPad } = useDevice();

  return (
    <tr key={session.id} className={RowClasses}>
      {/* Barcode */}
      {!isIPad && isComplexView && (
        <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center justify-center flex-shrink-0">
            {/* <Barcode value={session.sku} format="CODE128" /> */}
            <QrCode
              value={session.sku}
              level="H"
              includeMargin
              fgColor={session.bookings.map((booking) => getDriverCount(booking)).reduce((sum, val) => sum + val, 0) >= 16 ? "#ff0000" : "#000"}
              bgColor="transparent"
            />
          </div>
        </td>
      )}

      {/* staff count */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="text-center">{staffCount}</span>
          </div>
        </div>
      </td>

      {/* Session/Time */}
      <td className={`pl-4 pr-2 py-3 max-w-0 whitespace-nowrap relative`}>
        <div className="flex items-center flex-shrink-0 ">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {sessionTime}
              <br />
              <SessionVariantOptions type={view} session={session} hasUpdateSessionPermission={!!!trackStaff} />
              <br />
            </span>
          </div>
        </div>
        <div className={`absolute w-2 top-0 bottom-0 left-0 ${sessionColor}`} />
      </td>

      {/* Price */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span>${session.price}</span>
          </div>
        </div>
      </td>

      {/* Inventory Quantity */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="text-center">{session.inventory_quantity}</span>
          </div>
        </div>
      </td>

      {/* Booking Name */}
      <td className="px-2 py-3 max-w-fit font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly">
            {isBreakRace && <b className="text-base">BREAK RACE</b>}
            {filteredBookings.map((booking) => <Name booking={booking} key={booking.id} hasMultipleBooking={getSameSessionBookings(session, booking).length > 1}/>)}
          </div>
        </div>
      </td>

      {/* Notes */}
      <td className="px-2 py-3 max-w-fit">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly">
            {filteredBookings.map((booking) => (
              <Notes view={view} booking={booking} key={booking.id} refreshSessions={refreshSessions} />
            ))}
          </div>
        </div>
      </td>

      {/* Occasion */}
      <td className="px-2 py-3 max-w-fit">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly items-center">
            {filteredBookings.map((booking) => (
              <Occasion booking={booking} key={booking.id} />
            ))}
          </div>
        </div>
      </td>

      {/* booking time */}
      {isComplexView && (
        <td className="px-2 py-3 max-w-fit font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center h-full">
            <div className="flex flex-col w-full h-full justify-evenly">
              {filteredBookings.map((booking: ILineItemWithOrder) => (
                <div key={booking.id} className={`py-2 border-b flex flex-row }`} style={{ width: "max-content" }}>
                  {getBookingTime(booking)}
                </div>
              ))}
            </div>
          </div>
        </td>
      )}

      {/* paid price  */}
      {isComplexView && (
        <td className="px-2 py-3 max-w-fit font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center h-full">
            <div className="flex flex-col w-full h-full justify-evenly items-center">
              {filteredBookings.map((booking: ILineItemWithOrder) => (
                <div key={booking.id} className={`py-2 border-b flex flex-row`} style={{ width: "max-content" }}>
                  {getPaidPriceContent(booking)}
                </div>
              ))}
            </div>
          </div>
        </td>
      )}

      {/* No of drivers */}
      <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center space-x-2 h-full">
          <div className="flex flex-col text-center w-full h-full justify-evenly">
            {filteredBookings.map((booking: ILineItemWithOrder) => (
              <div className={`flex items-center justify-center py-2 border-b `} key={booking.id}>
                <div>{getDriverCount(booking)}</div>&nbsp;
              </div>
            ))}
          </div>
        </div>
      </td>

      {/* Race ready send sms */}
      {(!trackStaff || hasRrmFeatureAccess) && (
        <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
          <div className="flex items-center h-full">
            <div className="flex flex-col w-full h-full justify-evenly">
              <RaceReadyMsg session={session} view={view} rrmLog={rrmLog} filteredBookings={filteredBookings} />
            </div>
          </div>
        </td>
      )}

      {/* Registration status */}
      <td className="px-2 py-3 font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center h-full">
          <div className="flex flex-col w-full h-full justify-evenly">
            {filteredBookings.map((booking: ILineItemWithOrder) => (
              <div key={booking.id} className={`flex justify-start items-center gap-2`}>
                <div className={getStatusClass(booking.order.financial_status)}>{getFinancialStatus(booking.order)}</div>

                <button
                  onClick={() => handleViewOrderClick(booking)}
                  className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-purple-500  hover:bg-purple-700 rounded-full"
                >
                  View Order
                </button>
                {/* {view === "VR" && <GoogleReviewMsg order={booking.order} grmLog={grmLogs[booking.order.id]} />} */}
                <button
                  onClick={() => handleViewRegistrationModalOpen(booking)}
                  className={`items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center rounded-full ${
                    getRegisteredDriversCount(booking) === 0
                      ? "bg-black"
                      : getRegisteredDriversCount(booking) >= getDriverCount(booking)
                      ? "bg-[#FF00FF]"
                      : "bg-sky-500  hover:bg-sky-700"
                  }`}
                >
                  {getRegisteredDriversCount(booking)}
                </button>
              </div>
            ))}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default Session;
